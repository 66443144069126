import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        //console.log('navigation:connect')
        document.getElementById('mainNavbarCollapse').addEventListener('click', function (event) {
            $('#mainNavbar').toggleClass('open')
            $('#mainNavbarCollapse i').toggleClass('d-none')
            $('body').toggleClass('modal-open')
            $('.custom-backdrop').toggleClass('show')
        })

        document.addEventListener('swup:transitionStart', function (event) {
            $('body').removeClass('modal-open')
            $('.custom-backdrop').removeClass('show')
        })
    }


}
