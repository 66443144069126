import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        let href = this.element.getAttribute('href');
        $( this.element ).on('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            window.open(href, '_blank');
        })
    }
}
