import { Controller } from 'stimulus';
import Masonry from 'masonry-layout';

export default class extends Controller {
    connect() {
        let masonry = new Masonry( '.masonry', {
            // options...
        })
    }
}
