import { Controller } from 'stimulus';
require('image-picker');

export default class extends Controller {
    connect() {
        $('select.imagepicker').imagepicker()
    }

    disconnect() {
        $('select.imagepicker').remove()
    }
}
