import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        //console.log('email:connect')
        let self = this
        $('[data-email]').on('click', function (e) {
            e.preventDefault()
            let crypt = $(this).data('email')
            location.href = self.unCryptMailto(crypt)
        })
    }

    unCryptMailto(s) {
        let n = 0;
        let r = "";
        for (let i = 0; i < s.length; i++) {
            n = s.charCodeAt(i);
            if (n >= 8364) {
                n = 128;
            }
            r += String.fromCharCode(n - (1));
        }
        return r;
    }

    cryptMailto(s) {
        let n = 0;
        let r = '';
        for (let i = 0; i < s.length; i++) {
            n = s.charCodeAt(i);
            if (n >= 128) {
                n = 8364;
            }
            r += String.fromCharCode(n + 1);
        }
        return r;
    }
}
