import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        $('.markdown a').each(function() {
            let internal = new RegExp('/' + window.location.host + '/');
            if(!internal.test(this.href)) {
                let url = this.href
                $(this).click(function(event) {
                    event.preventDefault();
                    event.stopPropagation();
                    window.open(url, '_blank');
                });
            }
        })
    }
}
