import { Controller } from 'stimulus';
import Masonry from "masonry-layout";

export default class extends Controller {
    connect() {
        //console.log('filter:connect');

        let select = $('#filter')

        select.on('change', (event) => {
            let valueSelected = $("option:selected").val();
            //console.log(valueSelected)

            if (valueSelected.length > 0) {
                $('.filter-category').hide()
                $('.filter-' + valueSelected).show()
            } else {
                $('.filter-category').show()
            }
            let msnry = new Masonry( '.masonry', {
                // options...
            });


        })


    }
}
