import { Controller } from 'stimulus';
import  { Tooltip } from 'bootstrap';
import "vanilla-cookieconsent";
import "../js/jquery.cookie";

export default class extends Controller {
    connect() {
        //console.log('app:connect')

        this.deleteCookies()

        this.cookieConsent()

        this._initTooltips()

        window.addEventListener("orientationchange", function (e){
            window.location.reload()
        });
    }

    disconnect() {
        //console.log('app:disconnect')
        this._hideTooltips()

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    deleteCookies() {
        let legacyCookies = $.cookie('Legacy_Cookies')
        if (legacyCookies !== 'deleted') {
            for (let it in $.cookie()) {
                $.removeCookie(it)
            }
            $.cookie('Legacy_Cookies', 'deleted', { expires: 3650, path: '/' });
        }
    }

    cookieConsent() {
        window.addEventListener('load', this._initCookieConsent);
        window.addEventListener('swup:contentReplaced', this._initCookieConsent);
    }

    _initCookieConsent() {
        // obtain plugin
        this.cc = window.initCookieConsent();

        let title = '<i class="bi bi-shield-lock-fill pe-1"></i> Privacy Settings'

        // run plugin with your configuration
        this.cc.run({
            current_lang: 'en',
            autoclear_cookies: true,                   // default: false
            theme_css: false,                          // 🚨 replace with a valid path
            page_scripts: true,                        // default: false

            // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
            // delay: 0,                               // default: 0
            // auto_language: '',                      // default: null; could also be 'browser' or 'document'
            // autorun: true,                          // default: true
            // force_consent: false,                   // default: false
            // hide_from_bots: false,                  // default: false
            // remove_cookie_tables: false             // default: false
            cookie_name: 'Cookie_Consent',             // default: 'cc_cookie'
            // cookie_expiration: 182,                 // default: 182 (days)
            // cookie_necessary_only_expiration: 182   // default: disabled
            // cookie_domain: location.hostname,       // default: current domain
            // cookie_path: '/',                       // default: root
            // cookie_same_site: 'Lax',                // default: 'Lax'
            // use_rfc_cookie: false,                  // default: false
            // revision: 0,                            // default: 0

            onFirstAction: function(user_preferences, cookie){
                // callback triggered only once on the first accept/reject action
            },

            onAccept: function (cookie) {
                // callback triggered on the first accept/reject action, and after each page load
            },

            onChange: function (cookie, changed_categories) {
                // callback triggered when user changes preferences after consent has already been given
                window.location.reload()
            },

            languages: {
                'en': {
                    consent_modal: {
                        title: title,
                        description: 'This website uses cookies to give you the best possible experience. You can choose whether you only want to accept cookies that are necessary for the website to function or whether you also want to allow tracking cookies. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                        primary_btn: {
                            text: 'Accept all',
                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Reject all',
                            role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                        }
                    },
                    settings_modal: {
                        title: title,
                        save_settings_btn: 'Save settings',
                        accept_all_btn: 'Accept all',
                        reject_all_btn: 'Reject all',
                        close_btn_label: 'Close',
                        cookie_table_headers: [
                            {col1: 'Name'},
                            {col2: 'Domain'},
                            {col3: 'Expiration'},
                            {col4: 'Description'}
                        ],
                        blocks: [
                            {
                                title: 'Cookie usage',
                                description: 'This website uses cookies to ensure the basic functionalities and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. <!-- For more details relative to cookies and other sensitive data, please read the full <a href="/privacy-policy" class="cc-link">privacy policy</a>. -->'
                            }, {
                                title: 'Functional',
                                description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                                },
                                cookie_table: [             // list of all expected cookies
                                    {
                                        col1: 'PHPSESSID',
                                        col2: 'ciodor.de',
                                        col3: 'session',
                                        col4: 'This cookie was created by an application based on PHP. The purpose of this cookie is to recognize the user.',
                                    },
                                    {
                                        col1: 'Cookie_Consent',
                                        col2: 'ciodor.de',
                                        col3: '6 months',
                                        col4: 'This cookie identifies if users have accepted the use of cookies on the website.',
                                    },
                                    {
                                        col1: 'Legacy_Cookies',
                                        col2: 'ciodor.de',
                                        col3: '10 years',
                                        col4: 'This cookie identifies if legacy cookies have been deleted on your browser.',
                                    },
                                    {
                                        col1: 'VISITOR_INFO1_LIVE',
                                        col2: 'youtube.com',
                                        col3: '6 months',
                                        col4: 'This cookie provides bandwidth estimations.',
                                    },
                                    {
                                        col1: 'YSC',
                                        col2: 'youtube.com',
                                        col3: 'session',
                                        col4: 'This cookie stores and tracks interaction.',
                                    }
                                ]
                            }, {
                                title: 'Analytics',
                                description: 'These cookies allow the website to remember the choices you have made in the past',
                                toggle: {
                                    value: 'analytics',     // your cookie category
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [             // list of all expected cookies
                                    {
                                        col1: '_ga',
                                        col2: 'google.com',
                                        col3: '2 years',
                                        col4: 'Contains a randomly generated user ID. Using this ID, Google Analytics can recognize recurring users on this website and merge the data from previous visits.',
                                        //is_regex: true
                                    },
                                    {
                                        col1: '_gat',
                                        col2: 'google.com',
                                        col3: '1 minute',
                                        col4: 'Certain data is sent to Google Analytics at most once per minute. The cookie has a lifetime of one minute. As long as it is set, certain data transmissions are prevented.',
                                        //is_regex: true
                                    },
                                    {
                                        col1: '_gid',
                                        col2: 'google.com',
                                        col3: '1 day',
                                        col4: 'Contains a randomly generated user ID. Using this ID, Google Analytics can recognize recurring users on this website and merge the data from previous visits.',
                                    }
                                ]
                            },
                            /*{
                                title: 'Advertisement and Targeting cookies',
                                description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                                toggle: {
                                    value: 'targeting',
                                    enabled: false,
                                    readonly: false
                                }
                            },*/
                            /*{
                                title: 'More information',
                                description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact">contact us</a>.',
                            }
                            */
                        ]
                    }
                }
            }
        });

        let self = this
        $('.cc-link').on('click', function (e) {
            self.cc.hideSettings()
        })
    }

    _initTooltips() {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })

        //document.addEventListener('swup:contentReplaced', this._hideTooltips);
        //document.addEventListener('show.bs.modal', this._hideTooltips);
    }

    _hideTooltips() {
        this.tooltipList.forEach(element => element.hide())
    }
}
