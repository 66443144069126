import { Controller } from 'stimulus';
import  { Carousel } from 'bootstrap';
import ClipboardJS from "clipboard";

export default class extends Controller {
    connect() {
        this.id = $(this.element).prop('id')
        this.isGallery = $(this.element).parent().hasClass('modal-body')
        let carouselElement = $('#' + this.id)
        this.carousel = new Carousel(carouselElement)
        if (this.isGallery) {
            $('#gallery-slide-stop').hide()
            let href = $(this.element).data('href')

            let self = this
            self.caption(0)

            $('#' + this.id).on('slide.bs.carousel', function (e) {
                self.caption(e.to)
            })

            this.clipboard = new ClipboardJS('#gallery-item-link', {
                container: document.getElementById('galleryModal')
            })

            this.clipboard.on('success', function(e) {
                alert('Copied to clipboard!');
            })

            $('#' + this.id + ' .carousel-indicators button').on('click', function (e) {
                $('.tooltip').removeClass('show')
            })

            let hash = window.location.hash.substr(1)
            if (hash.length > 0) {
                $('[data-bs-slide-to="' + hash +'"]').click()
                self.caption(hash)
            }

            $( window ).on( 'hashchange', function( e ) {
                let hash = window.location.hash.substr(1)
                if ($('#galleryModal').hasClass('show') && hash.length > 0) {
                    $('button[data-bs-slide-to="' + hash +'"]').click()
                } else {
                    $('img[data-bs-slide-to="' + hash +'"]').click()
                }
                self.caption(hash)
            });

            document.getElementById('gallery-slide-start').addEventListener('click', (event) => {
                $('#gallery-slide-stop').show()
                $('#gallery-slide-start').hide()
                this.carousel.cycle()
            });

            document.getElementById('gallery-slide-stop').addEventListener('click', (event) => {
                $('#gallery-slide-start').show()
                $('#gallery-slide-stop').hide()
                this.carousel.pause()
            });

            document.addEventListener('show.bs.modal', (event) => {
                this.carousel = new Carousel(carouselElement)
                this.carousel.cycle()
                this.carousel.pause()
            });
            document.addEventListener('hide.bs.modal', (event) => {
                this.carousel.dispose()
            });
        } else {
            this.carousel = new Carousel(carouselElement)
            this.carousel.cycle()
        }
    }

    caption(index) {
        //console.log('carousel:caption #' + index)
        let href = $(this.element).data('href')
        let caption = $('#carousel-item-' + index  + ' .carousel-caption h5').html()
        let date = $('#carousel-item-' + index  + ' .carousel-caption h6').html()
        $('#gallery-caption').text(caption)
        $('#gallery-date').text(date)
        $('#gallery-item-link').attr('data-clipboard-text', href + '#' + index)
    }

    disconnect() {
        this.carousel.dispose()
        if(this.isGallery) {
            this.clipboard.destroy()
        }
        $('#' + this.id).remove()
    }
}
