import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        let id = this.element.getAttribute('data-audio')
        let iframe = document.getElementById(id)
        this.element.addEventListener('hide.bs.modal', (event) => {
            let src = iframe.getAttribute('src')
            iframe.contentWindow.location.replace(src)
        });
    }
}

