'use strict';

import {Controller} from 'stimulus';
import {Modal, Carousel} from 'bootstrap';

export default class extends Controller {
    connect() {
        //console.log('gallery:connect');

        /*
        const imageGrid = $(".image-grid");

        const imgs = imageGrid.find("img");
        const lightboxModal = $("#lightbox-modal");
        const modalBody = lightboxModal.find(".container-fluid")[0];
        const bsModal = new Modal(lightboxModal[0]);

        $('.image-grid a').on('click', function (e) {
            e.preventDefault();
            console.log('click');
            const currentImg = $(this).find("img")[0];
            const lightboxCarousel = $("#lightboxCarousel");
            console.log(lightboxCarousel);
            if (lightboxCarousel) {
                console.log('lightboxCarousel:if');
                const parentCol = $(this).parent().parent();
                const index = [...parentCol.parent().children()].indexOf(parentCol);
                const bsCarousel = new Carousel(lightboxCarousel);
                bsCarousel.to(index);
            } else {
                console.log('lightboxCarousel:else');
                let markup = "";
                const currentImgSrc = currentImg.prop("src");
                imgs.each(function() {
                    let imgSrc = $(this).prop("src");
                    let imgAlt = $(this).prop("alt");
                    let imgCaption = $(this).data("caption");
                    markup += '<div class="carousel-item' + (currentImgSrc === imgSrc ? ' active' : '') + '><img src="' + imgSrc + '" alt="' + imgAlt + '">' + (imgCaption ? '<div class="carousel-caption"><p class="m-0">' + imgCaption + '</p></div>' : '') + '</div>';
                });
                modalBody.html('<div id="lightboxCarousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="false"><div class="carousel-inner">' + markup + '</div><button class="carousel-control-prev" type="button" data-bs-target="#lightboxCarousel" data-bs-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="visually-hidden">Previous</span></button><button class="carousel-control-next" type="button" data-bs-target="#lightboxCarousel" data-bs-slide="next"><span class="carousel-control-next-icon" aria-hidden="true"></span><span class="visually-hidden">Next</span></button></div>');
            }
            console.log(modalBody);

            bsModal.show();
        });

         */

    }


}
