import { Controller } from "stimulus";
import {loadScript} from "../js/load-script";

export default class extends Controller {
    static targets = ["collapseButton", "player"];

    connect() {
        //console.log('youtube:connect')
        loadScript("https://www.youtube.com/iframe_api");
        this.initPlayer()
    }

    initPlayer() {
        //console.log('youtube:initPlayer')
        document.addEventListener('swup:pageView', function(e, id){
            //console.log('swup:pageView')
            window.YT.ready(function () {
                player = new YT.Player('player', {
                    width: '100%',
                    videoId: id,
                    playerVars: {
                        rel: 0,
                        controls: 0,
                        showinfo: 0,
                        autoplay: 1,
                        loop: 1,
                        playlist: id,
                    },
                    events: {
                        'onReady': (event) => {
                            event.target.mute();
                            event.target.playVideo();
                        }
                    }
                });
            });
        });

        document.getElementById("muteButton").addEventListener('click', function (e) {
            if (!!player.isMuted()) {
                player.unMute();
                document.getElementById("muteIcon").classList.remove('bi-volume-up-fill');
                document.getElementById("muteIcon").classList.add('bi-volume-mute-fill');
            } else {
                player.mute();
                document.getElementById("muteIcon").classList.remove('bi-volume-mute-fill');
                document.getElementById("muteIcon").classList.add('bi-volume-up-fill');
            }
        });


    }
}
