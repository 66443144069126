import { Controller } from 'stimulus';

export default class extends (Controller) {
    connect() {
        let form = this.element.getAttribute('data-form');

        let addButton = $('.add-collection-item');
        let list = $( addButton.data( 'list' ) );

        let prototype = list.data( 'prototype' )
        prototype = $($.parseHTML(prototype)).html();

        list.data( 'prototype', prototype )
        let first = addButton.data( 'first' );
        let items = list.children();

        //console.log(items.length);

        let $body = $( 'form[name="' + form + '"]' );
        $body.on( 'click', '.add-collection-item', function( e ) {
            let list = $( $( this ).data( 'list' ) );
            // Try to find the counter of the list or use the length of the list
            let counter = list.data( 'widget-counter' ) | list.children().length;
            // grab the prototype template
            let newWidget = list.data( 'prototype' );
            // replace the "__name__" used in the id and name of the prototype
            // with a number that's unique to your emails
            // end name attribute looks like name="contact[emails][2]"
            newWidget = newWidget.replace( /__name__/g, counter );
            // Increase the counter
            counter++;
            // And store it, the length cannot be used if deleting widgets is allowed
            list.data( 'widget-counter', counter );
            // create a new list element and add it to the list
            let newElem = $( list.data( 'widget-tags' ) );
            newElem.find( '.input-group' ).prepend( newWidget );
            newElem.appendTo( list );
        } );

        $body.on( 'click', '.del-collection-item', function ( e ) {
            $( this ).parent().parent().remove();
            list.data( 'widget-counter', counter-- );
        });

        $('fieldset').each(function() {
            $(this).remove();
        });

        items.each(function () {
            let input = $(this).find('input');
            if (input.attr('id') !== first) {
                if (input.val() !== '') {
                    $(this).find('input').remove();
                    let newElem = $(list.data('widget-tags'));
                    newElem.find('.input-group').prepend(input);
                    newElem.appendTo(list);
                } else {
                    $(this).remove();
                }
            }
        });

    }
    /*
    connect() {
        console.log('collection:connect');

        const addButtonGroup = document.createElement('div');
        addButtonGroup.classList.add('btn-group');
        const addButton = document.createElement('button');
        addButton.setAttribute('type', 'button');
        addButton.classList.add('btn', 'btn-outline-light');
        addButton.innerHTML = '<i class="bi bi-plus-lg"></i>';
        addButtonGroup.append(addButton);
        this.element.append(addButtonGroup);

        addButton.addEventListener('click', event => {
            let widget = this.element.querySelector('[data-prototype]');

            console.log(widget);


            let prototype = widget.dataset.prototype;

            console.log(this.index);

            if (undefined === this.index) {
                this.index = this.element.querySelectorAll('[data-form-collection-item]').length - 1;
            }
            ++this.index;

            console.log(this.index);


            let form = prototype.replace(/__name__/g, this.index.toString()).trim();
            widget.append(this._createElementFromHTML(form));
            this.element.closest('form').dispatchEvent(new CustomEvent('change'));

            let items = widget.querySelectorAll('[data-form-collection-item]');
            // @ts-ignore
            this._addDeleteButton(items[items.length - 1]);
        });

        this.element.querySelectorAll('[data-form-collection-item]').forEach((item) => {
            this._addDeleteButton(item);
        });
    }

    _createElementFromHTML(htmlString) {
        let div = document.createElement('div');
        div.innerHTML = htmlString.trim();
        return div.firstChild;
    }

    _addDeleteButton(id) {
        let item = document.getElementById(id);
        const button = this._createElementFromHTML('<button class="btn-close float-end" type="button" aria-label="Close"></button>');
        const cardBody = item.querySelector('.card-body');
        if (null !== cardBody) {
            cardBody.prepend(button);
        } else {
            item.append(button);
        }
        button.addEventListener('click', () => this._deleteItem(id));
    }

    _deleteItem(id) {
        document.getElementById(id).remove();
        this.element.closest('form').dispatchEvent(new CustomEvent('change'));
    }
    */
}