export function initInfiniteScroll(route, offset) {
    window.__offset = offset;
    window.__route = route;
    window.__isFetching = false;
    window.__hasMoreData = true;

    if ($('#infinite-target').height() < $(window).height()) {
        getmoredata()
    }

    //else {
    //    moreButton()
    //}

    $('#infinite-get-more button').click(function () {
        getmoredata()
    })

    $(window).scroll(function () {
        if($(window).scrollTop() + $(window).height() >= $(document).height()){
            if(!window.__isFetching && window.__hasMoreData) {
                getmoredata();
            }
        }
    })
}

function moreButton() {
    if ($('#infinite-target').height() < $(window).height()) {
        $('#infinite-get-more').removeClass('d-none').addClass('d-flex')
    } else {
        $('#infinite-get-more').removeClass('d-flex').addClass('d-none')
    }
}

function getmoredata() {
    window.__isFetching = true;
    $('#infinite-spinner').addClass('show');
    $.ajax({
        type: "POST",
        url: window.__route + '?offset=' + window.__offset,
        dataType: "json",
        cache: false,
        success: function (response) {
            $('#infinite-target').append(response.html);
            $('#infinite-indicator').text(response.value);
            if (!response.hasMoreData) {
                $('#infinite-spinner').remove();
            } else {
                $('#infinite-spinner').removeClass('show');
            }

            $('#infinite-page-' + response.offset + ' .fadein').each(function() {
                $(this).animate({opacity: 1}, 500);
            });

            window.__isFetching = false;
            window.__hasMoreData = response.hasMoreData;
            window.__offset = response.offset
            //moreButton()
        },
        error: function (response) {
            console.log(response);
        }
    });
}
