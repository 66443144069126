import { Controller } from 'stimulus';
import {initInfiniteScroll} from "../js/infinite-scroll";

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        let route = $(this.element).data('route')
        let offset = $(this.element).data('offset')

        initInfiniteScroll(route, offset)

    }
}
