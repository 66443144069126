import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        let id = this.element.getAttribute('data-video')
        let url = this.element.getAttribute('data-iframe-url');
        let video = document.getElementById(id)
        this.element.addEventListener('hide.bs.modal', (event) => {
            video.setAttribute('src', '')
        });
        this.element.addEventListener('show.bs.modal', (event) => {
            video.setAttribute('src', url)
        });
    }
}

